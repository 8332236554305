const INTER = [
    {
        space_src: 'baidujs',
        space_type: 'inters',
        space_id: 'u6943929',
        space_js: '//sjgfzpgb.timesaurora.com/production/u/po/resource/xlh/openjs/x/ex.js'
    }];
const BANNER = [
    {
        space_src: 'baidujs',
        space_type: 'native',
        space_id: 'u6943930',
        space_js: '//sjgfzpgb.timesaurora.com/common/m_hg/pdz_pzn.js'
    }
];
const NATIVE_1 = [
    {
        space_src: 'baidujs',
        space_type: 'native',
        space_id: 'u6943931',
        space_js: '//sjgfzpgb.timesaurora.com/site/n/ih/static/q-ea/source/qar.js'
    }
];
const NATIVE_2 = [
    {
        space_src: 'baidujs',
        space_type: 'native',
        space_id: 'u6943932',
        space_js: '//sjgfzpgb.timesaurora.com/source/oji/common/rf/brb/static/y.js'
    }
];
const NATIVE_3 = [
    {
        space_src: 'baidujs',
        space_type: 'native',
        space_id: 'u6943933',
        space_js: '//sjgfzpgb.timesaurora.com/site/pkj/s/production/gc-scc.js'
    }
];


/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: INTER,
    Home_banner: BANNER,
    Main_native: NATIVE_1,
    Classroom_native: NATIVE_2,
    Wikipedia_cat_native: NATIVE_1,
    Wikipedia_dog_native: NATIVE_2,
    Wikipedia_other_native: NATIVE_3,
    ConstellationList_banner: BANNER,
    ConstellationList_native: NATIVE_1,
    ArticleDetail_inter: INTER,
    ArticleDetail_banner: BANNER,
    ArticleDetail_native: NATIVE_1,
    PetDetail_inter: INTER,
    PetDetail_banner: BANNER,
    PetDetail_native: NATIVE_1,
    Mood_dialog: NATIVE_3,
};
