import { onBeforeMount, onMounted, onBeforeUnmount, onErrorCaptured, watch } from 'vue';
import { useOeReport } from './useOeReport';
import { useReport } from './useReport';
import { useVisible } from './useVisible';
const { PageDeep, _calculatedPageCount } = useOeReport();
const { reportExp } = useReport();
const { visible } = useVisible();
export function useLifeCycle(isSecondPage) {
    let pageDeep = new PageDeep();
    /** 记录页面深度 */
    let deepCount = sessionStorage.getItem('HAA_PAGE_DEEP');
    let countNumber = deepCount ? (+deepCount + 1) : (_calculatedPageCount.count + 1);
    sessionStorage.setItem('HAA_PAGE_DEEP', countNumber + '');
    watch(() => visible.value, () => {
        if (visible.value)
            pageDeep.show();
        else
            pageDeep.hide();
    });
    onBeforeMount(() => {
        pageDeep.init(isSecondPage);
    });
    onMounted(() => {
        pageDeep.show();
    });
    onBeforeUnmount(() => {
        pageDeep.hide();
    });
    onErrorCaptured((error) => {
        reportExp('ComponentError', error);
    });
}
